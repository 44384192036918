<template>
  <div class="style-form custom_scrollbar">
    <el-form :rules="formRules" :ref="formId" v-if="dto" class="style-form-inner-body" :model="dto">
      <el-form-item prop="name" :label="$locale.main.fields.name">
        <el-input v-model="dto.name" autocomplete="off"></el-input>        
      </el-form-item>
      <el-form-item prop="description" :label="$locale.main.fields.description">
        <el-input type="textarea" v-model="dto.description" autocomplete="off"></el-input>        
      </el-form-item>
      <PropertySet v-if="properties.length" ref="propertySet" :items="properties" :dto="dto"/>
    </el-form>
  </div>
</template>

<script type="ts">
import Style, { StyleDTO } from '@/services/MapEditor/domain/model/Style/Style'
import StyleCreateCommand from '@/services/MapEditor/application/command/StyleCreateCommand'
import StyleUpdateCommand from '@/services/MapEditor/application/command/StyleUpdateCommand'
import StyleByGuidQuery from '@/services/MapEditor/application/query/StyleByGuidQuery'
import StyleTypePropertiesQuery from '@/services/MapEditor/application/query/StyleTypePropertiesQuery'
import PropertySet from '@/services/MapEditor/infrastructure/components/PropertySet/index.vue'

export default {
  name: 'PointForm',
  props: ['formId', 'parentFormId', 'projectId', 'projectGuid', 'style_', 'submitCallback', 'styleCreatedCallback', 'styleUpdatedCallback'],
  inject: ['getEventBus', 'getCommandBus', 'getQueryBus'],
  components: {
    PropertySet
  },
  watch: {
    styleCreated: function (location) {
      this.getQueryBus().execute(
        new StyleByGuidQuery(
          location.replace('/styles/', '')
        )
      ).then((styleDto) => {
        let style = Style.create(styleDto);
        if (typeof this.styleCreatedCallback == 'function') {
          this.styleCreatedCallback(style);
        }
        this.getEventBus().$emit('styleCreated', style, this.parentFormId);        
      }); 
    } 
  },
  data() {
    return {
      types: null,
      dto: null,
      styleType: null,
      properties: [],
      defaultFormRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'blur'
        }
      },
      formRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'blur'
        }
      }     
    }
  },
  computed: {
    styleCreated() {
      return this.$store.getters['Style/getLocation'];
    }
  },
  methods: {
    updateRules() {
      let rules = this.defaultFormRules;
      this.properties.forEach((prop) => {
        rules[prop.id] = {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: this.triggerType(prop.type)
        }
      });
      this.formRules = rules;
    },
    triggerType(name) {
      return 'change';
    },
    async submit(callback) {
      let form = this.$refs[this.formId];
      form.validate(valid => {
        if (valid) {
          if (typeof this.style_ !== 'undefined') {
            this.style_.setName(this.dto.name);
            this.style_.setType(this.dto.type);
            this.style_.setProperties(this.dto.properties);

            this.getCommandBus().execute(
              new StyleUpdateCommand(
                this.style_.getGuid(),
                this.style_.getName(),
                this.style_.getDescription(),
                this.style_.getType(),
                this.style_.getProperties()
              )
            ).then((response) => {
              if (typeof callback == 'function') {
                callback();
              }
              if (typeof this.styleUpdatedCallback == 'function') {
                this.styleUpdatedCallback(this.style_);
              }
              this.getEventBus().$emit('styleUpdated', this.style_);
            });
          } else {
            let style = Style.create(this.dto);
            this.getCommandBus().execute(
              new StyleCreateCommand(
                style.getName(),
                style.getDescription(),
                style.getType(),
                style.getProperties(),
                this.projectId
              )
            ).then((response) => {
              if (typeof callback == 'function') {
                callback(response);
              }              
            });
          }
        }
      });
    },
    propertiesMounted(properties) {
      if (typeof this.style_ !== 'undefined' && this.$refs.propertySet == properties) {
        properties.setValues(this.style_.getProperties());
      }
    }
  },
  async mounted() {
    this.properties = await this.getQueryBus().execute(
      new StyleTypePropertiesQuery('point')
    );
    if (typeof this.style_ !== 'undefined') {
      this.dto = new StyleDTO ({
        id: this.style_.getId(),
        guid: this.style_.getGuid(),
        name: this.style_.getName(),
        description: this.style_.getDescription(),
        type: this.style_.getType(),
        properties: this.style_.getProperties()
      });
    } else {      
      this.dto = new StyleDTO({
        type: 'point'
      });
    }
    this.getEventBus().$on('propertiesMounted', this.propertiesMounted);
  }
}
</script>